import { Component, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { InsightsService } from 'src/app/services/insights.service';
import { AppSessionStorage } from 'src/app/utils/app-session-storage-utils';
import { Utility } from 'src/app/utils/app-utils';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';

interface DataPoint {
  time: string;
  count: number;
  percentage?: number;
}

interface ChartData {
  chart: string;
  today: DataPoint[];
  previous_day: DataPoint[];
}

@Component({
  selector: 'app-alarm-time-of-the-day',
  templateUrl: './alarm-time-of-the-day.component.html',
  styleUrls: ['./alarm-time-of-the-day.component.scss'],
})
export class AlarmTimeOfTheDayComponent
  implements AfterViewInit, AfterViewChecked, OnInit {
  dynamicWidth: number = 300;
  dynamicHeight: number = 180;
  liveUpdates: Subscription;
  private dateRangeSubscription: Subscription;
  private startDate: number | undefined;
  private endDate: number | undefined;

  data: ChartData = {
    "chart": "alarm-time-of-the-day",
    "today": [
      { "time": "00:00", "count": 0 }
    ],
    "previous_day": [
      { "time": "00:00", "count": 0 },
    ]
  };

  alarmTypes: any[] = [];
  severities: any[] = [];
  statuses: any[] = [];

  selectedStatus: string = '';
  selectedType: string = '';
  selectedSeverity: string = '';

  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService, public analyticsDashboardService: AnalyticalDashboardService) { }

  async ngOnInit() {
    await this.getAlarmsAroundTheDay();
    this.graphUpdateListener();
    this.listenToDateRangeChanges();
    await this.getAlarmTypes();
    await this.getSeverities();
    await this.getStatuses();
  }

  listenToDateRangeChanges() {
    this.dateRangeSubscription = this.insightsService.currentDateRange.subscribe(dateRange => {
      this.startDate = dateRange.startDate;
      this.endDate = dateRange.endDate;
      this.getAlarmsAroundTheDay(this.startDate, this.endDate);
    });
  }

  async getAlarmsAroundTheDay(startDate?: number, endDate?: number, status?: string, type?: string, severity?: string) {
    let res = await this.insightsService.getAlarmsByTimeOfTheDay(startDate, endDate, status, type, severity);
    if (res && res.success && res.data && res.data.results) {
      this.calculateChartValues(res.data.results);
    } else { }
  }

  async graphUpdateListener() {
    this.liveUpdates = this.insightsService.updateIncidents().subscribe(async (update: any) => {
      if (update === 'newIncident') {
        const now = new Date();
        const endDate = now.getTime();
        await this.getAlarmsAroundTheDay(this.startDate, endDate);
      }
    });
  }

  private calculateChartValues(values: any) {
    const user = AppSessionStorage.getUser();
    const timeZone = user.timeZone;
    const today_iso = new Date();

    let today = new Date(today_iso.toLocaleString('en-US', { timeZone })).setHours(0, 0, 0, 0);
    let previous_date = new Date(new Date(today_iso.toLocaleString('en-US', { timeZone })).setDate(new Date(today).getDate() - 1));

    let formattedData = {
      'previous_day': this.formatAndFillDayHours(values['previousPeriodAlarmsCount'], previous_date, timeZone),
      'today': this.formatAndFillDayHours(values['currentPeriodAlarmsCount'], today, timeZone, true)
    };

    this.data = {
      "chart": this.data.chart,
      "today": formattedData.today,
      "previous_day": formattedData.previous_day
    };

    this.cdr.detectChanges();
  }

  private formatAndFillDayHours(value: any, day: any, timeZone: string, percentage_key: boolean = false) {
    let formatted = [];
    const utcDayStart = new Date(day).setHours(0, 0, 0, 0);
    const utcDayEnd = new Date(day).setHours(23, 59, 59, 999);

    // Loop through the hours of the day
    for (let i = utcDayStart; i <= utcDayEnd; i += 60 * 60 * 1000) {
      const localTime = new Date(i).toLocaleString('en-US', {
        timeZone: timeZone,
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
      });

      const date = new Date(i);

      let detail = {
        "time": localTime,
        "count": 0
      };

      if (percentage_key) {
        detail["percentage"] = 0;
      }

      if (value[i]) {
        let data = value[i];
        detail["count"] = data["count"];
        if (percentage_key) {
          detail["percentage"] = data["percentage"];
        }
      }

      formatted.push(detail);
    }

    return formatted;
  }

  ngAfterViewInit(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize')
  onResize() {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  private updateSvgDimensions() {
    const parentWidth = document
      .querySelector('#line-alarm-time-of-the-day')
      ?.getBoundingClientRect().width;
    if (parentWidth) {
      this.dynamicWidth = parentWidth ? parentWidth - 10 : 300;
    }

    this.dynamicHeight = window.innerHeight > 1300 ? 300 : window.innerHeight >= 1080 ? 225 : window.innerHeight >= 1024 ? 173 : window.innerHeight >= 934 ? 195 : 258;
  }

  async getAlarmTypes() {
    this.alarmTypes = await this.analyticsDashboardService.getAlarmsTypes();
  }

  async getSeverities() {
    this.severities = await this.analyticsDashboardService.getAlarmsSeverityTypes();
  }

  async getStatuses() {
    this.statuses = await this.analyticsDashboardService.getAlarmsStatusTypes();
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.liveUpdates);
    Utility.Unsubscribe(this.dateRangeSubscription);
  }

  onFilterChange(): void {
    this.getAlarmsAroundTheDay(this.startDate, this.endDate, this.selectedStatus, this.selectedType, this.selectedSeverity);
  }
}
