import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlarmService } from '../services/alarm.service';
import { Alarm } from 'src/models/alarm.model';

@Component({
  selector: 'app-alarm-tabs',
  templateUrl: './alarm-tabs.component.html',
  styleUrls: ['./alarm-tabs.component.scss']
})
export class AlarmTabsComponent implements OnInit {
  selectedTab: string = 'progress';
  @Output() tabSelected = new EventEmitter<string>();

  selectedAlarmSubscriber: Subscription;
  chatBotSelectedSubscriber: Subscription;
  public isContainZone: boolean = false;
  selectedAlarm: Alarm;

  constructor(public alarmService: AlarmService) { }

  ngOnInit(): void {
    this.selectedAlarmListener();
    this.chatBotSelectedListener();
  }

  selectedAlarmListener() {
    this.selectedAlarmSubscriber = this.alarmService.getOnAlarmSelectedListener().subscribe((alarm: Alarm) => {
      this.selectedAlarm = alarm;
      if (alarm) {
        // this.selectTab('progress');
        this.isContainZone = this.alarmService.isContainZone(alarm);
      } else {
        this.selectTab('map');
      }
    });
  }

  chatBotSelectedListener() {
    this.chatBotSelectedSubscriber = this.alarmService.getChatBotSelectedListener().subscribe((selected: boolean) => {
      if (selected) {
        this.selectTab('chatbot');
      }
    });
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
    this.tabSelected.emit(this.selectedTab);
  }

  ngOnDestroy(): void {
    this.selectedAlarmSubscriber.unsubscribe();
    this.chatBotSelectedSubscriber.unsubscribe();
  }

}
