<div class="app-workflow full-height">
	<div class="box-header d-flex align-items-center">
		<!-- <div class="d-flex align-items-center">
			<i class="fa fa-bars" aria-hidden="true"></i>
		</div> -->
		<div class="workflow-topbar">
			<p class="workflow-title">{{ "WORKFLOW.TITLE" | translate }}</p>
			<span *ngIf="alarm" class="buttons mr-3">
				<i [class]="this.speechService.allowAudio ? 'fa fa-volume-up audio' : 'fa fa-volume-off audio'"
					aria-hidden="true" (click)="toggleAudio()">
				</i>
				<i class="fa fa-envelope mr-4" [title]="'WORKFLOW.SEND_MAIL' | translate" aria-hidden="true"
					(click)="showSendMailBox()" [class.active-link]="isSendMailBoxShown==true"></i>
				<i class="fa fa-comment-o mr-4" [class.active-link]="isCommentBoxShown==true"
					[title]="'WORKFLOW.ADD_COMMENT' | translate" aria-hidden="true" (click)="showCommentBox()"></i>
				<i class="fa fa-external-link mr-4 pt-1" [title]="'WORKFLOW.ESCALATE_ALARM' | translate" aria-hidden="true"
					[class.active-link]="isEscalationConfirmationShown==true" (click)="showEscalationConfirmation()"
					*ngIf="alarm.status!=EscalatedAlarm"></i>
				<!-- chat -->
				<i class="fa fa-commenting mr-4" [class.active-link]="isChatShown==true" aria-hidden="true"
					[title]="'WORKFLOW.CHAT' | translate" (click)="showChatBox()"></i>
				<!-- Audio toggle -->
				<!-- <i class="fa fa-volume-up" aria-hidden="true" *ngIf="this.speechService.allowAudio" (click)="toggleAudio()"></i>
				<i class="fa fa-volume-off" aria-hidden="true" *ngIf="!this.speechService.allowAudio"
					(click)="toggleAudio()"></i> -->
			</span>
		</div>
	</div>
	<div *ngIf="alarm" class="content-height">
		<div class="sections div1 full-height"
			*ngIf="isCommentBoxShown || isEscalationConfirmationShown || isSendMailBoxShown || isChatShown">
			<div *ngIf="isCommentBoxShown">
				<app-add-comment [comment]="previousComments" (commentAdded)="addComment($event)"
					(commentCancelled)="onCommentCancel()" (commentsChange)="onCommentsTextChange($event)">
				</app-add-comment>
			</div>
			<div *ngIf="isSendMailBoxShown">
				<app-send-mail (sendEmailCancelled)="isSendMailBoxShown=false"></app-send-mail>
			</div>
			<div *ngIf="isEscalationConfirmationShown">
				<app-escalation-confirmation (alarmEscalated)="escalateAlarm()"
					(alarmEscalationCancelled)="isEscalationConfirmationShown=false" [title]="title" [calledFrom]="calledFrom"
					[header]="header"></app-escalation-confirmation>
			</div>
			<!-- Chat Users -->
			<div *ngIf="isChatShown">
				<app-chat-users [alarm]=alarm (ChatBoxCancelled)="isChatShown=false"></app-chat-users>
			</div>
		</div>
		<div class="sections div2 full-height"
			[hidden]="!(!isCommentBoxShown && !isEscalationConfirmationShown && !isSendMailBoxShown && !isChatShown)">
			<div *ngIf="question">
				<app-question [question]="question" [workflowComplete]="workflowComplete"></app-question>
			</div>
		</div>
		<div class="sections div3 full-height">
			<div class="heading"> {{ "WORKFLOW.BRANCH_CONTACT" | translate }}</div>
			<ul class="list-group">
				<li *ngFor="let manager of managers">
					<p>{{" " +manager.designation}}</p> <br>
					<p class="manager-name limit-contains" matTooltip="{{manager.name}}">{{manager.name}}</p><br>
					<p>{{ "WORKFLOW.MOBILE" | translate }} : {{manager.contact + " "}}</p>
					<span class="ml-2">
						<i class="fa fa-phone-square" aria-hidden="true"></i>
					</span>
					<hr>
				</li>
			</ul>
		</div>
	</div>
</div>