import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
declare var bootstrap: any;
@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements AfterViewInit {
  constructor() {}

  @Input() title: string = '';
  @Input() content: string = '';

  ngAfterViewInit(): void {
    const popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-toggle="popover"]')
    );
    popoverTriggerList.map((popoverTriggerEl: any) => {
      return new bootstrap.Popover(popoverTriggerEl, {
        template: `
        <div class="popover custom-popover" role="tooltip">
          <div class="arrow"></div>
          <h3 class="title popover-header"></h3>
          <div class="popover-body"></div>
        </div>
      `,
      });
    });
  }
}
