<div id="alarms-filter-component">

	<!-- Alarms filters -->
	<div *ngIf="filterType === 'ALARMS'">
		<div>
			<form mat-form>
				<div class="alarms-filter-row">
					<div class="filter-row">
						<div class="action-felids">
							<button mat-raised-button class="filter-btn action" (click)="showDialogConfirmation(actionType.takeover)">
								<img [src]="utility.getImagePath('escalate-ltblue.png')" alt="escalate-ltblue" class="action-icon">
								{{ "ANALYTICAL_ALARM_HISTORY.TAKEOVER" | translate }}</button>
							<button mat-raised-button class="filter-btn action"
								(click)="showDialogConfirmation(actionType.assign)"><img [src]="utility.getImagePath('Assign.png')"
									alt="assign" class="action-icon">
								{{ "ANALYTICAL_ALARM_HISTORY.ASSIGN" | translate }}</button>
							<!-- <button mat-raised-button
								class="filter-btn action"
								(click)="showDialogConfirmation(actionType.unAssign)"><img
									[src]="utility.getImagePath('Un-Assign.png')" alt="unassign"
									class="action-icon">
								{{ "ANALYTICAL_ALARM_HISTORY.UN_ASSIGN" | translate }}</button> -->

						</div>

						<div class="filter-header" [class.filter-header-align]="filterType === 'ALARMS'">
							<div class="filter-icon">
								<i class="fa fa-filter" aria-hidden="true"></i>
							</div>
							<button mat-raised-button class="filter-btn first-btn" [class.selected-filter]="selectedFilter.region"
								(click)="changeSelectedFilter(SelectedType.region)">{{ "ANALYTICAL_ALARM_HISTORY.REGION" | translate
								}}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.sites"
								(click)="changeSelectedFilter(SelectedType.sites)">{{ "ANALYTICAL_ALARM_HISTORY.SITE" | translate
								}}</button>
							<button mat-raised-button class="filter-btn assigned-user-btn"
								[class.selected-filter]="selectedFilter.operators"
								(click)="changeSelectedFilter(SelectedType.operators)">{{ "ANALYTICAL_ALARM_HISTORY.ASSIGNED_USER" |
								translate }}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.type"
								(click)="changeSelectedFilter(SelectedType.type)">{{ "ANALYTICAL_ALARM_HISTORY.TYPE" | translate
								}}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.alarmsSeverity"
								(click)="changeSelectedFilter(SelectedType.alarmsSeverity)">{{ "ANALYTICAL_ALARM_HISTORY.SEVERITY" |
								translate }}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.status"
								(click)="changeSelectedFilter(SelectedType.status)">{{ "ANALYTICAL_ALARM_HISTORY.STATUS" | translate
								}}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.timeRange"
								(click)="changeSelectedFilter(SelectedType.timeRange)">{{ "ANALYTICAL_ALARM_HISTORY.TIME_RANGE" |
								translate }}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.alarmInfo"
								(click)="changeSelectedFilter(SelectedType.alarmInfo)">{{ "ANALYTICAL_ALARM_HISTORY.ALARM_INFO" |
								translate }}</button>
							<button mat-raised-button class="filter-btn last-btn"
								[class.selected-filter]="selectedFilter.shortAlarmID"
								(click)="changeSelectedFilter(SelectedType.shortAlarmID)">{{ "ANALYTICAL_ALARM_HISTORY.ALARM_SHORT_ID" |
								translate }}</button>
							<!-- Ask AI -->
							<input type="text" placeholder="{{ 'AI.QUESTION' | translate }}" class="ai-input" [(ngModel)]="askAiText"
								name="askAiText" autocomplete="off" (keydown.enter)="ai_handleEnterKey($event)">
							<button mat-raised-button class="ai-btn" (click)="askAI()">
								{{ "AI.ASK" | translate }}
							</button>
							<mat-spinner class="progress" diameter="40" *ngIf="spinner"></mat-spinner>
							<!-- <mat-progress-bar mode="indeterminate" class="progress"></mat-progress-bar> -->
						</div>
					</div>

					<div class="alarms-filter-row alarms-row-div">
						<div class="input-field action-options">

							<app-rm-multi-select [placeholder]="placeholder.allOperator" class="multiple-select"
								[inputData]="activeOnlineOfflineOperators" [isSingleSelect]="true"
								[showDropDown]="isShowingUserDrawdown" (dropdownToggled)="toggleUserDrawdown($event)"
								(dataChanged)="operatorSelectFieldsForAction = $event">
							</app-rm-multi-select>

						</div>

						<div class="input-field filter alarms-filter alarms-input"
							[class.operators-filter-input]="filterType ===  'OPERATORS'">

							<app-rm-multi-select [placeholder]="placeholder.allRegion" class="multiple-select" [inputData]="regions"
								*ngIf="selectedFilter.region" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="regionSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allSites" class="multiple-select" [inputData]="sites"
								*ngIf="selectedFilter.sites" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="sitesSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allUsers" class="multiple-select"
								[inputData]="formatedOperatorsDataForFilter" *ngIf="selectedFilter.operators"
								[showDropDown]="isShowingUserDrawdown" (dropdownToggled)="toggleUserDrawdown($event)"
								(dataChanged)="operatorSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allType" class="multiple-select" [inputData]="alarmsType"
								*ngIf="selectedFilter.type" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="typeSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allSeverity" class="multiple-select"
								[inputData]="alarmsSeverity" *ngIf="selectedFilter.alarmsSeverity"
								[showDropDown]="isShowingUserDrawdown" (dropdownToggled)="toggleUserDrawdown($event)"
								(dataChanged)="severitySelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allStatus" class="multiple-select"
								[inputData]="alarmsStatusTypes" *ngIf="selectedFilter.status" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="selectFields = $event">
							</app-rm-multi-select>

							<div class="date-time-picker-div owl-form-field-infix date-picker-1" [hidden]="!selectedFilter.timeRange">
								<input [owlDateTimeTrigger]="dateAndTime1" class="date-time-picker" [owlDateTime]="dateAndTime1"
									[max]="currentDate" [(ngModel)]="selectedMoments" [selectMode]="'rangeFrom'"
									[placeholder]="placeholder.selectDateAndTime" name="dateTimeRange">

								<div class="mat-form-field-suffix ng-tns-c20-74 ng-star-inserted" *ngIf="selectedFilter.timeRange"
									[owlDateTimeTrigger]="dateAndTime1">
									<mat-datepicker-toggle class="date-picker-toggle mat-datepicker-toggle">
										<i class="fas fa-calendar-alt" aria-hidden="true"></i></mat-datepicker-toggle>
								</div>
								<owl-date-time #dateAndTime1 showSecondsTimer="true" [hour12Timer]="isHour12Format"
									class="date-picker-1"></owl-date-time>
							</div>

							<div class="date-time-picker-div owl-form-field-infix date-picker-2" [hidden]="!selectedFilter.timeRange">
								<input [owlDateTimeTrigger]="dateAndTime2" class="date-time-picker" [owlDateTime]="dateAndTime2"
									[min]="selectedMoments[0]" [max]="currentDate" [(ngModel)]="selectedMoments" [selectMode]="'rangeTo'"
									[placeholder]="placeholder.selectDateAndTime" name="dateTimeRange">

								<div class="mat-form-field-suffix ng-tns-c20-74 ng-star-inserted" *ngIf="selectedFilter.timeRange"
									[owlDateTimeTrigger]="dateAndTime2">
									<mat-datepicker-toggle class="date-picker-toggle mat-datepicker-toggle">
										<i class="fas fa-calendar-alt" aria-hidden="true"></i></mat-datepicker-toggle>
								</div>
								<owl-date-time #dateAndTime2 showSecondsTimer="true" [hour12Timer]="isHour12Format"></owl-date-time>
							</div>

							<input type="text" *ngIf="selectedFilter.alarmInfo"
								placeholder="  {{ 'ANALYTICAL_ALARM_HISTORY.ENTER_ALARM_INFO' | translate }} " class="search-input"
								[(ngModel)]="alarmInfoText" name="alarmInfo" autocomplete="off"
								(keydown.enter)="handleEnterKey($event)">

							<input type="text" *ngIf="selectedFilter.shortAlarmID"
								placeholder="  {{ 'ANALYTICAL_ALARM_HISTORY.ENTER_SHORT_ALARM_ID' | translate }} " class="search-input"
								[(ngModel)]="shortAlarmIdText" name="shortAlarmsId" autocomplete="off"
								(keydown.enter)="handleEnterKey($event)">

							<div class="filter-apply">
								<button mat-raised-button class="filter-btn action apply-btn clear-action" (click)="filterList()">
									{{ "ANALYTICAL_FILTERS.FILTER" | translate }}</button>
								<button mat-raised-button class="filter-btn action apply-btn clear-action" (click)="resetFilters()">
									{{ "ANALYTICAL_FILTERS.CLEAR" | translate }}</button>
							</div>

							<button mat-raised-button class="filter-btn action apply-btn clear-action pdf-btn"
								(click)="downloadAlarmsPdfReport()">
								<i class="fa fa-download download-icon" aria-hidden="true"></i>
								{{ "ANALYTICAL_CHARTS.DOWNLOAD_REPORT_PDF" | translate }}</button>

							<button mat-raised-button class="filter-btn action apply-btn clear-action pdf-btn"
								(click)="downloadAlarmsCsvReport()">
								<i class="fa fa-download download-icon" aria-hidden="true"></i>
								{{ "ANALYTICAL_CHARTS.DOWNLOAD_REPORT_CSV" | translate }}</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>

	<!-- Operators filters -->
	<div *ngIf="filterType === 'OPERATORS'">
		<div [class.sites-filter]="filterType === 'SITES'">
			<form mat-form>
				<div class="alarms-filter-row">
					<div class="filter-row">
						<div class="filter-header" [class.filter-header-align]="filterType === 'ALARMS'">
							<div class="filter-icon">
								<i class="fa fa-filter" aria-hidden="true"></i>
							</div>
							<button mat-raised-button class="filter-btn first-btn" [class.selected-filter]="selectedFilter.operators"
								(click)="changeSelectedFilter(SelectedType.operators)">{{ "ANALYTICAL_ALARM_HISTORY.OPERATOR" |
								translate}}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.region"
								(click)="changeSelectedFilter(SelectedType.region)">{{ "ANALYTICAL_ALARM_HISTORY.REGION" | translate
								}}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.sites"
								(click)="changeSelectedFilter(SelectedType.sites)">{{ "ANALYTICAL_ALARM_HISTORY.SITE" | translate
								}}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.status"
								[class.last-btn]="filterType ===  'OPERATORS'" (click)="changeSelectedFilter(SelectedType.status)">{{
								"ANALYTICAL_ALARM_HISTORY.STATUS" | translate }}</button>
						</div>
					</div>

					<div class="alarms-filter-row alarms-row-div">
						<div class="input-field filter " [class.operators-filter-input]="filterType ===  'OPERATORS'">

							<app-rm-multi-select [placeholder]="placeholder.allOperators" class="multiple-select"
								[inputData]="activeOperators" *ngIf="selectedFilter.operators" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="operatorSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allRegion" class="multiple-select" [inputData]="regions"
								*ngIf="selectedFilter.region" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="regionSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allSites" class="multiple-select" [inputData]="sites"
								*ngIf="selectedFilter.sites" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="sitesSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allStatus" class="multiple-select"
								[inputData]="OperatorStatuses" *ngIf="selectedFilter.status" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="OperatorStatusSelectFelid = $event">
							</app-rm-multi-select>

							<div class="filter-apply">
								<button mat-raised-button class="filter-btn action apply-btn clear-action" (click)="filterList()">
									{{ "ANALYTICAL_FILTERS.FILTER" | translate }}</button>
								<button mat-raised-button class="filter-btn action apply-btn clear-action" (click)="resetFilters()">
									{{ "ANALYTICAL_FILTERS.CLEAR" | translate }}</button>
							</div>

							<button mat-raised-button class="filter-btn action apply-btn clear-action pdf-btn"
								(click)="downloadOperatorsPdfReport()">
								<i class="fa fa-download download-icon" aria-hidden="true"></i>
								{{ "ANALYTICAL_CHARTS.DOWNLOAD_REPORT_PDF" | translate }}</button>

							<button mat-raised-button class="filter-btn action apply-btn clear-action pdf-btn"
								(click)="downloadOperatorsCsvReport()">
								<i class="fa fa-download download-icon" aria-hidden="true"></i>
								{{ "ANALYTICAL_CHARTS.DOWNLOAD_REPORT_CSV" | translate }}</button>

						</div>
					</div>
				</div>
			</form>
		</div>
	</div>

	<!-- Sites filters -->
	<div *ngIf="filterType === 'SITES' && !isConfidenceMonitoring">
		<div [class.sites-filter]="filterType === 'SITES'">
			<form mat-form>
				<div class="alarms-filter-row">
					<div class="filter-row">
						<div class="filter-header" [class.filter-header-align]="filterType === 'ALARMS'">
							<div class="filter-icon">
								<i class="fa fa-filter" aria-hidden="true"></i>
							</div>
							<button mat-raised-button class="filter-btn first-btn" [class.selected-filter]="selectedFilter.sites"
								(click)="changeSelectedFilter(SelectedType.sites)">{{ "ANALYTICAL_ALARM_HISTORY.SITE" | translate
								}}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.region"
								(click)="changeSelectedFilter(SelectedType.region)">{{ "ANALYTICAL_ALARM_HISTORY.REGION" | translate
								}}</button>
							<button mat-raised-button class="filter-btn" *ngIf="filterType ===  'SITES'"
								[class.selected-filter]="selectedFilter.sitePriority"
								(click)="changeSelectedFilter(SelectedType.sitePriority)">{{ "ANALYTICAL_ALARM_HISTORY.SITE_PRIORITY" |
								translate }}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.operators"
								(click)="changeSelectedFilter(SelectedType.operators)">{{ "ANALYTICAL_ALARM_HISTORY.OPERATOR" |
								translate }}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.status"
								[class.last-btn]="filterType === 'SITES'" (click)="changeSelectedFilter(SelectedType.status)">{{
								"ANALYTICAL_ALARM_HISTORY.STATUS" | translate }}</button>
						</div>
					</div>

					<div class="alarms-filter-row alarms-row-div">
						<div class="input-field filter " [class.operators-filter-input]="filterType ===  'OPERATORS'">

							<app-rm-multi-select [placeholder]="placeholder.allSites" class="multiple-select" [inputData]="sites"
								*ngIf="selectedFilter.sites" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="sitesSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allRegion" class="multiple-select" [inputData]="regions"
								*ngIf="selectedFilter.region" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="regionSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allSitesPriority" class="multiple-select"
								[inputData]="SitesPriorityTypes" *ngIf="selectedFilter.sitePriority"
								[showDropDown]="isShowingUserDrawdown" (dropdownToggled)="toggleUserDrawdown($event)"
								(dataChanged)="typeSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allOperators" class="multiple-select"
								[inputData]="activeOperators" *ngIf="selectedFilter.operators" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="operatorSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allStatus" class="multiple-select"
								[inputData]="sitesStatuses" *ngIf="selectedFilter.status" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="statusSelectFields = $event">
							</app-rm-multi-select>

							<div class="filter-apply">
								<button mat-raised-button class="filter-btn action apply-btn clear-action" (click)="filterList()">
									{{ "ANALYTICAL_FILTERS.FILTER" | translate }}</button>
								<button mat-raised-button class="filter-btn action apply-btn clear-action" (click)="resetFilters()">
									{{ "ANALYTICAL_FILTERS.CLEAR" | translate }}</button>
							</div>

							<button mat-raised-button class="filter-btn action apply-btn clear-action pdf-btn"
								(click)="downloadSitesPdfReport()">
								<i class="fa fa-download download-icon" aria-hidden="true"></i>
								{{ "ANALYTICAL_CHARTS.DOWNLOAD_REPORT_PDF" | translate }}</button>

							<button mat-raised-button class="filter-btn action apply-btn clear-action pdf-btn"
								(click)="downloadSitesCsvReport()">
								<i class="fa fa-download download-icon" aria-hidden="true"></i>
								{{ "ANALYTICAL_CHARTS.DOWNLOAD_REPORT_CSV" | translate }}</button>

						</div>
					</div>
				</div>
			</form>
		</div>
	</div>

	<!-- Confidence monitoring filters -->
	<div *ngIf="filterType === 'SITES' && isConfidenceMonitoring">
		<div [class.sites-filter]="filterType === 'SITES'">
			<form mat-form>
				<div class="alarms-filter-row" id="sites-filter">
					<div class="filter-row">
						<div class="filter-header" [class.filter-header-align]="filterType === 'ALARMS'">
							<div class="filter-icon">
								<i class="fa fa-filter" aria-hidden="true"></i>
							</div>

							<button mat-raised-button class="filter-btn first-btn" [class.selected-filter]="selectedFilter.sites"
								(click)="changeSelectedFilter(SelectedType.sites)">{{ "ANALYTICAL_ALARM_HISTORY.SITE" | translate
								}}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.region"
								(click)="changeSelectedFilter(SelectedType.region)">{{ "ANALYTICAL_ALARM_HISTORY.REGION" | translate
								}}</button>
							<button mat-raised-button class="filter-btn" *ngIf="filterType === 'SITES'"
								[class.selected-filter]="selectedFilter.sitePriority"
								(click)="changeSelectedFilter(SelectedType.sitePriority)">{{ "ANALYTICAL_ALARM_HISTORY.SITE_PRIORITY" |
								translate }}</button>
							<button mat-raised-button class="filter-btn" [class.selected-filter]="selectedFilter.status"
								[class.last-btn]="filterType === 'SITES'" (click)="changeSelectedFilter(SelectedType.status)">{{
								"ANALYTICAL_ALARM_HISTORY.STATUS" | translate }}</button>

							<!-- General search filter - new req -->
							<!-- <input type="text"
								placeholder=" {{ 'FILTER_DATA.SEARCH_SITES' | translate }} "
								class="general-search"
								[(ngModel)]="generalSearch"
								name="generalSearch"
								autocomplete="off"
								(keydown.enter)="handleEnterKey($event)"> -->

						</div>
					</div>

					<div class="alarms-filter-row alarms-row-div">
						<div class="input-field filter " [class.operators-filter-input]="filterType ===  'OPERATORS'">

							<app-rm-multi-select [placeholder]="placeholder.allSites | translate" class="multiple-select"
								[inputData]="sites" *ngIf="selectedFilter.sites" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="sitesSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allRegion | translate" class="multiple-select"
								[inputData]="regions" *ngIf="selectedFilter.region" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="regionSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allSitesPriority | translate" class="multiple-select"
								[inputData]="SitesPriorityTypes" *ngIf="selectedFilter.sitePriority"
								[showDropDown]="isShowingUserDrawdown" (dropdownToggled)="toggleUserDrawdown($event)"
								(dataChanged)="typeSelectFields = $event">
							</app-rm-multi-select>

							<app-rm-multi-select [placeholder]="placeholder.allStatus | translate" class="multiple-select"
								[inputData]="sitesStatuses" *ngIf="selectedFilter.status" [showDropDown]="isShowingUserDrawdown"
								(dropdownToggled)="toggleUserDrawdown($event)" (dataChanged)="statusSelectFields = $event">
							</app-rm-multi-select>

							<div class="filter-apply">
								<button mat-raised-button class="filter-btn action apply-btn clear-action" (click)="filterList()">
									{{ "ANALYTICAL_FILTERS.FILTER" | translate }}</button>
								<button mat-raised-button class="filter-btn action apply-btn clear-action" (click)="resetFilters()">
									{{ "ANALYTICAL_FILTERS.CLEAR" | translate }}</button>
							</div>

						</div>
					</div>
				</div>
			</form>
		</div>
	</div>

</div>