<ul class="card-nav d-flex flex-column">

  <li *ngIf="selectedAlarm" [class.active]="selectedTab === 'progress'" (click)="selectTab('progress')">
    <i class="fa fa-check-circle-o" aria-hidden="true"></i>
  </li>

  <li *ngIf="selectedAlarm" [class.active]="selectedTab === 'workflow'" (click)="selectTab('workflow')">
    <i class="fa fa-address-book-o" aria-hidden="true"></i>
  </li>

  <li *ngIf="selectedAlarm" [class.active]="selectedTab === 'actions'" (click)="selectTab('actions')">
    <i class="fa fa-list" aria-hidden="true"></i>
  </li>

  <li [class.active]="selectedTab === 'map'" (click)="selectTab('map')">
    <i class="fa fa-map-marker" aria-hidden="true"></i>
  </li>

  <li [class.active]="selectedTab === 'zone'" (click)="selectTab('zone')" *ngIf="isContainZone">
    <i class="fa fa-map-pin" aria-hidden="true"></i>
  </li>

  <li *ngIf="selectedAlarm" [class.active]="selectedTab === 'assets'" (click)="selectTab('assets')">
    <i class="fa fa-file-text-o" aria-hidden="true"></i>
  </li>

  <li [class.active]="selectedTab === 'send-alarm'" (click)="selectTab('send-alarm')">
    <i class="fa fa-plus" aria-hidden="true"></i>
  </li>

  <li [class.active]="selectedTab === 'chatbot'" (click)="selectTab('chatbot')">
    <!-- <i class="fa fa-comments" aria-hidden="true"></i> -->
    <img src="./assets/images/chaticon.svg" alt="chatbot" class="chatbot-icon" />
  </li>

</ul>