import { Subject } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { UserSessionStateService } from '../services/user-session-state.service';
import { AlarmService } from '../services/alarm.service';
import { Utility } from '../utils/app-utils';
import { Subscription } from 'rxjs';
import { Alarm } from "src/models/alarm.model";
import { MapService } from '../services/map.service';
import { AppConfigService } from '../app-config.service';
import { VideoStreamService } from '../services/video-stream.service';

enum AssetDocType {
	image = 'img',
	pdf = 'pdf'
}

@Component({
	selector: 'app-asset-viewer',
	templateUrl: './asset-viewer.component.html',
	styleUrls: ['./asset-viewer.component.scss']
})

export class AssetViewerComponent implements OnInit {
	public shouldShowImg = false;
	public shouldShowPDF = false;
	public assetUrl: string = null;
	utility = Utility;

	public isContainZone: boolean = false;
	selectedAlarmSubscriber: Subscription;
	selectedSite: any;

	@Input() assetDoc: Subject<any>;
	selectedAlarm: Alarm;
	selectedTab: string = 'progress';
	public assetDocSelectedChanged = false;
	chatBotUrl: string;

	constructor(
		public alarmService: AlarmService, public mapService: MapService,
		private userSessionStateService: UserSessionStateService, private appConfigService: AppConfigService, public videoStreamService: VideoStreamService) {
		this.assetDoc = null;
	}

	ngOnInit() {
		this.assetDoc.subscribe((asset) => {
			this.initDefaults();
			// If asset is null or undefined it mean's there is no asset doc selected
			if (asset) {
				this.shouldShowImg = asset.type === AssetDocType.image;
				this.shouldShowPDF = asset.type === AssetDocType.pdf;
				this.assetUrl = asset.url;
			}
		});

		this.selectedAlarmListener();

		this.chatBotUrl = this.appConfigService.appConfig.ASK_RAMSYS_URL;
	}

	public initDefaults() {
		// By default show map view
		this.shouldShowImg = false;
		this.shouldShowPDF = false;
		this.assetUrl = null;
	}

	close() {
		this.onAssetSelectShow(null);
		this.shouldShowPDF = false;
		this.shouldShowImg = false;
		if (this.selectedAlarm) {
			this.userSessionStateService.saveSelectedAssetId(this.selectedAlarm.id, null);
		}
	}

	ngOnDestroy(): void {
		this.selectedAlarmSubscriber.unsubscribe();
	}

	//  called when alarm is selected
	selectedAlarmListener() {
		this.selectedAlarmSubscriber = this.alarmService.getOnAlarmSelectedListener().subscribe((alarm: Alarm) => {
			if (alarm) {
				this.selectedAlarm = alarm;
				this.isContainZone = this.alarmService.isContainZone(alarm);
				this.close();

				if (!this.isContainZone && this.selectedTab === 'zone') {
					this.selectedTab = 'progress';
				}

				// Ensure a valid tab is selected when switching alarms
				this.onTabSelected(this.selectedTab);
			}
		});
	}

	async onTabSelected(tab: string) {
		const previousTab = this.selectedTab; // Store the current tab before updating
		this.close();
		if (tab === 'zone' && !this.isContainZone) {
			this.selectedTab = 'progress';
		} else {
			this.selectedTab = tab;
		}

		// Call playVideoFeeds when navigating away from 'send-alarm' tab		
		if (previousTab === 'send-alarm' && this.selectedAlarm) {
			this.alarmService.playVideoFeeds(this.selectedAlarm);
		} else {
			if (this.alarmService.alarmList.length === 0) {
				await this.videoStreamService.setEmptyVideoSources();
			}
		}
	}

	onAssetSelectShow(asset: any) {
		this.assetDocSelectedChanged = null;
		this.assetDoc.next(asset);
		// Let Angular CD to detect change.
		setTimeout(() => {
			this.assetDocSelectedChanged = asset ? true : false;
		}, 100);
	}

}
