import { Component, OnInit } from '@angular/core';
import { ConfidenceMonitoringService } from 'src/app/services/confidence-monitoring.service';
import { AlarmService } from '../services/alarm.service';
import { VideoStreamService } from '../services/video-stream.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-send-alarm',
  templateUrl: './send-alarm.component.html',
  styleUrls: ['./send-alarm.component.scss']
})
export class SendAlarmComponent implements OnInit {
  displayedColumns = [
    'Sites',
    'Region',
    'Site Priority',
    'Status'
  ];

  public getSitesChangedSubscriper: Subscription;

  constructor(
    public confidenceMonitoringService: ConfidenceMonitoringService,
    public alarmService: AlarmService, public videoStreamService: VideoStreamService) { }

  ngOnInit() {
    this.getSitesChangedSubscriper = this.confidenceMonitoringService.getSitesChangedNotification().subscribe(async (branch: any) => {
      await this.videoStreamService.setEmptyVideoSources();
      await this.videoStreamService.fetchVideoSourcesList(branch.id);
    });
  }

  ngOnDestroy() {
    this.getSitesChangedSubscriper.unsubscribe();
  }

}
