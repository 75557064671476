import { Component, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { InsightsService } from 'src/app/services/insights.service';
import { Utility } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-false-rate',
  templateUrl: './false-rate.component.html',
  styleUrls: ['./false-rate.component.scss'],
})
export class FalseRateComponent implements AfterViewInit, AfterViewChecked {
  dynamicWidth: number = 230;
  dynamicHeight: number = 250;
  dynamicRadius: number = 100;
  arcSize: number = 25;

  pieData = [];

  liveUpdates: Subscription;
  private dateRangeSubscription: Subscription;
  private startDate: number | undefined;
  private endDate: number | undefined;

  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService) { }

  async ngOnInit() {
    await this.getRate();
    this.incidentsListener();
    this.listenToDateRangeChanges();
  }

  listenToDateRangeChanges() {
    this.dateRangeSubscription = this.insightsService.currentDateRange.subscribe(dateRange => {
      this.startDate = dateRange.startDate;
      this.endDate = dateRange.endDate;
      this.getRate(this.startDate, this.endDate);
    });
  }

  async getRate(startDate?: number, endDate?: number) {
    const res = await this.insightsService.getFalseAlarmsRate(startDate, endDate);
    if (res && res.success && res.data && res.data.results) {
      this.pieData = res.data.results
        .filter(item => item.percentage !== null && item.percentage !== 0)
        .map(item => ({
          label: item.role,
          value: item.currentPeriodAlarmsCount,
          percentage: Math.round(item.percentage)
        }))
        .sort((a, b) => b.percentage - a.percentage);
    } else {
      this.pieData = [];
    }
  }

  incidentsListener() {
    this.liveUpdates = this.insightsService.updateIncidents().subscribe(async (update: any) => {
      if (update === 'alarmsUpdate') {
        const now = new Date();
        const endDate = now.getTime();
        await this.getRate(this.startDate, endDate);
      }
    });
  }

  ngAfterViewInit() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  private updateChartWidth() {
    const screenWidth = window.innerWidth;
    const screenheight = window.innerHeight;
    if (screenWidth > 3000 && screenheight > 1300) {
      this.dynamicWidth = 400;
      this.dynamicHeight = 360;
      this.dynamicRadius = 130;
      this.arcSize = 35;
    } else if (screenheight >= 1080) {
      this.dynamicWidth = 200;      
      this.dynamicHeight = 290;
      this.dynamicRadius = 90;
      this.arcSize = 25;
    }
    else if (screenheight >= 1024) {
      this.dynamicWidth = 200;
      this.dynamicHeight = 280;
      this.dynamicRadius = 100;
      this.arcSize = 25;
    }
    else {
      this.dynamicWidth = 200;
      this.dynamicHeight = 270;
      this.dynamicRadius = 90;
      this.arcSize = 25;
    }
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.liveUpdates);
    Utility.Unsubscribe(this.dateRangeSubscription);
  }
}
