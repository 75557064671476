export enum WEB_SOCKET_EVENTS {
	NEW_ALARM = 'newAlarm',
	UNATTENDED_COUNT = 'unattendCount',
	ESCALATE = 'escalate',
	ESCALATE_COUNT = 'escalateCount',
	ASSIGN_ALARM = 'assignAlarm',
	DENY_ALARM = 'denyAlarm',
	REMOVE_ALARM = 'removeAlarm',
	TAKEN_OVER_ALARM = 'takenOverAlarm',
	MESSAGE = 'message',
	START_VERIFICATION = 'startVerification',
	NEW_ACTION_HISTORY_EVENT = 'newActionHistoryEvent',
	REFRESH_SUPER_ROUTES = 'refreshSuperRoutes',
	NOT_ALLOWED_ACTION = "NotAllowedAction",
	LOG_OUT = "LOG_OUT",
	UPDATE_MAP = "updateMap",
	CHAT_MESSAGE_NOTIFICATION = "chatMessageNotification",
	CHAT_MESSAGE = "chatMessage",
	CHAT_STARTED = "chatStarted",
	CHAT_REMOVED_MEMBER = "chatRemoveMember",
	CHAT_REMOVED_NOTIFED = "chatRemoveNotified",
	CHAT_ADD_MEMBER = "chatAddMember",
	CHAT_ADD_NOTIFED = "chatAddNotified",
	ALARM_STATUS_CHANGE = "ALARM_STATUS_CHANGE",
	RIC_STATUS_CHANGE = "ALARM_RIC_STATUS_CHANGE_EVENT",
	MULTIPLE_ALARMS_RIC_STATUS_CHANGE = "MULTIPLE_ALARMS_RIC_STATUS_CHANGE_EVENT"
}
